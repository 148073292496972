.env {
  position: relative;
  height: calc(60vw - 20vw);
  width: 60vw;
  cursor: pointer;
  @media screen and (max-width: 1100px) {
    width: 80vw;
    height: 50vw;
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    height: 70vw;
  }

  transform: scale(0.4) rotate(20deg) translateX(50%);
  transition: all 0.4s;
  @media screen and (max-width: 700px) {
    transform: scale(0.6) translateY(50%) rotate(17deg);
  }
  &__text {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 50%;
    color: rgb(236, 161, 108);
    font-size: 5rem;
    font-family: "Cookie", cursive;
    @media screen and (max-width: 700px) {
      position: absolute;
      top: -7rem;
      left: 50%;
      transform: translateX(-50%);
      // top: 50%;
      width: 60rem;
      text-align: center;
    }
  }

  &__side {
    background-color: bisque;
    background-image: radial-gradient(rgb(233, 215, 194), rgb(211, 179, 143));
    position: absolute;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: 10px solid transparent;
    transition: all 0.4s;

    &--2 {
      transform: rotateY(180deg);
      border: none;

      &__opening {
        background-color: rgb(248, 219, 185);
        background-image: radial-gradient(
          rgb(248, 219, 185),
          rgb(201, 174, 143)
        );
        width: 100%;
        height: 50%;
        clip-path: polygon(
          100% 0,
          54% 95%,
          53% 97%,
          52% 99%,
          51% 100%,
          50% 100%,
          49% 100%,
          48% 99%,
          47% 97%,
          46% 95%,
          0 0
        );
        transform: rotateX(180deg);
        transform-origin: top;
      }
      &__inside {
        position: absolute;
        top: 0;
        background-color: rgb(119, 71, 15);
        width: 100%;
        height: 50%;
        clip-path: polygon(
          -1000% -50%,
          1000% -50%,
          100% 0,
          54% 95%,
          53% 97%,
          52% 99%,
          51% 100%,
          50% 100%,
          49% 100%,
          48% 99%,
          47% 97%,
          46% 95%,
          0 0
        );
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__letter {
        height: 50rem;
        width: 80%;
        background-color: white;
        background-image: radial-gradient(
          rgb(255, 255, 255),
          rgb(196, 196, 196)
        );
        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
          padding-top: 4rem;
          font-family: "Love Ya Like A Sister", cursive;
          font-size: 2rem;
          text-decoration: none;
          text-align: center;
          @media screen and (max-width: 700px) {
            font-size: 1.7rem;
            padding-top: 10rem;
          }
          @media screen and (max-width: 550px) {
            font-size: 1.4rem;
            padding-top: 13rem;
          }
          a {
            text-decoration: none;
            color: rgb(89, 89, 184);
            transition: all 0.4s;
            &:hover {
              color: rgb(214, 114, 114);
            }
          }
          strong {
            font-size: 3rem;
            font-weight: 200;
            padding: 2rem;
          }
        }
      }
    }
    &--1 {
      border-image: repeating-linear-gradient(
          -45deg,
          rgba(6, 6, 163, 0.8),
          rgba(6, 6, 163, 0.8) 25px,
          transparent 25px,
          transparent 40px,
          rgb(158, 0, 0) 40px,
          rgb(158, 0, 0) 65px,
          transparent 65px,
          transparent 80px
        )
        12/10px;
      transition: all 0.4s;
    }
  }
  &.active &__side--1 {
    transform: rotateY(180deg);
  }
  &.active &__side--2 {
    transform: rotateY(0deg);
  }
  &.active {
    transform: scale(1) rotate(0);
    cursor: default;
    &:hover {
      box-shadow: none;
      transform: scale(1) rotate(0);
    }
  }
  &:hover {
    box-shadow: 5px 0 400px black;
    transform: scale(0.45) rotate(20deg) translateX(50%);
    @media screen and (max-width: 700px) {
      transform: scale(0.65) translateY(50%) rotate(17deg);
    }
  }

  &__timbre {
    height: 10rem;
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (max-width: 750px) {
      height: 7rem;
    }
    @media screen and (max-width: 500px) {
      height: 5rem;
    }
  }
  &__stamp {
    height: 10rem;
    position: absolute;
    top: 3rem;
    right: 3rem;
    @media screen and (max-width: 750px) {
      height: 7rem;
    }
    @media screen and (max-width: 500px) {
      height: 5rem;
    }
  }
  &__info {
    font-family: "Love Ya Like A Sister", cursive;

    color: black;
    position: absolute;
    top: 50%;
    right: 10rem;
    font-size: 2rem;
    text-align: center;
    @media screen and (max-width: 750px) {
      font-size: 1.6rem;
      right: 5rem;
      top: 40%;
    }
    @media screen and (max-width: 450px) {
      font-size: 1.2rem;
      top: 60%;
      right: 5%;
    }

    &__item {
      border-bottom: 1px solid black;
      padding: 2rem 3rem 0 3rem;
    }
    &__name {
      text-transform: uppercase;
    }
  }

  &.active {
    cursor: default;
  }
}
