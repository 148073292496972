.mug {
  position: relative;

  &__cup {
    height: 15rem;
    width: 15rem;
    background-color: wheat;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px black, inset 0 0 3px black;
    z-index: 10;
  }

  &__wall {
    height: 13rem;
    width: 13rem;
    background-color: wheat;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px black, inset 0 0 60px black;
  }
  &__coffee {
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    background-color: rgb(53, 28, 28);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__handle {
    background-color: wheat;
    position: absolute;
    left: -5rem;
    top: 50%;
    transform: translateY(-50%);
    height: 3rem;
    width: 6rem;
    border-radius: 10% / 50%;
    box-shadow: inset 0 0 3px black;
  }
  &__steam {
    height: 90%;
    width: 90%;
    border-radius: 50%;
    background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.562) 0%,
      transparent 70%
    );
    opacity: 0;
    transition: all 0.8s;
    &:hover {
      opacity: 1;
    }
  }
}
