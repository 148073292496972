.projects {
  background-color: rgb(14, 15, 31);
  background-image: url(../img/wall.jpg);
  background-size: cover;
  color: white;
  position: relative;
  overflow: hidden;
  &__back {
    position: fixed;
    left: 0;
    top: 0;
    font-size: 5rem;
    font-family: "DM Serif Display", serif;
    text-transform: capitalize;
    padding: 3rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    color: rgb(255, 255, 255);
    opacity: 0.5;
    transition: all 0.4s;
    cursor: pointer;
    @media screen and (max-width: 550px){
      font-size: 3rem ;

    }
    &__text {
      font-size: 3rem;
      @media screen and (max-width: 550px){
        font-size: 2rem; 
  
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}
.tvs {
  display: flex;
  gap: 4rem;
}

.bor {
  border: 2px solid rgb(80, 71, 61);
  height: 20rem;
  width: 30rem;
  box-shadow: inset 0 0 30px black, 0 4px 3px black;

  background-color: rgb(212, 184, 117);
  padding: 1rem;
  background-color: rgb(73, 21, 21);
}
.bigbor {
  border: 1px solid rgb(0, 0, 0);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: gray;
  border-radius: 5px;
  box-shadow: inset 0 0 30px black;
}
.screensq {
  border: 1px solid rgb(0, 0, 0);
  height: 95%;
  width: 75%;
  box-shadow: inset -7px 0 30px black;
  background-color: brown;
  margin: 0.5rem;
}

.screen {
  border-top: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  height: 90%;
  width: 90%;
  margin: 1rem;
  border-radius: 150% 150% 150% 150%/40% 40% 40% 40%;
  overflow: hidden;
  color: black;
  z-index: 20;
  box-shadow: 0 0 30px rgb(183, 180, 221);
}
.screen2 {
  border-right: 2px solid currentColor;
  border-left: 2px solid currentColor;
  height: 100%;
  width: 100%;

  background-color: black;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgb(183, 180, 221);

  border-radius: 40% 40% 40% 40%/ 150% 150% 150% 150%;
}

.control {
  border-left: 1px solid rgb(0, 0, 0);
  height: 100%;
}
.switch {
  background-color: rgb(49, 49, 49);
  border: 1px solid black;
  border-radius: 3px;
  width: 5rem;
  height: 40%;
  margin: 0.5rem;
}
.sound {
  width: 5rem;
  height: 50%;
  margin: 0.5rem;
  background-image: radial-gradient(black 1px, transparent 0);
  background-size: 5px 4px;
}
.circle {
  height: 3rem;
  width: 3rem;
  border: 2px dotted rgb(87, 86, 86);
  border-radius: 50%;
  margin: 2px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circlesm {
  height: 2rem;
  width: 2rem;
  background-color: rgb(63, 63, 63);
  border: 2px solid gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -1px 0 3px rgb(39, 38, 38);

  &--1 {
    transform: rotate(20deg);
  }
  &--2 {
    transform: rotate(73deg);
  }
}
.rect {
  width: 16px;
  height: 7px;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectsm {
  width: 16px;
  height: 4px;
  background-color: rgb(83, 76, 76);
}
.papattes {
  width: 30rem;
  display: flex;
  justify-content: space-between;
}
.papatte {
  height: 3px;
  width: 5px;
  background-color: rgb(39, 39, 39);
  margin: 0 9px;
}
.antenna {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
}
.socle {
  background-color: rgb(105, 104, 103);
  width: 4rem;
  height: 1.5rem;
  border-radius: 50% 50% 100% 0% / 100% 100% 0% 0%;
  box-shadow: inset 5px 0 8px rgb(73, 73, 73);
  border: 1px solid rgb(68, 67, 67);
}
.horns {
  display: flex;
}
.horn {
  display: flex;
  flex-direction: column;
  align-items: center;
  &--1 {
    transform: rotate(30deg) translate(15px, -4px);
    transform-origin: bottom center;
  }
  &--2 {
    transform: rotate(-30deg) translate(-15px, -4px);
    transform-origin: bottom center;
  }
}
.hr {
  height: 5rem;
  border-right: 1px solid rgb(78, 78, 78);
}
.hr1 {
  background-color: rgb(92, 91, 91);

  width: 1px;
}
.hr2 {
  width: 3px;
  background-color: rgb(88, 88, 87);
}
.hr3 {
  width: 4px;
  background-color: rgb(103, 102, 104);
}
.hrtip {
  height: 4px;
  width: 3px;
  background-color: rgb(112, 112, 112);
}

.pic {
  height: 100%;
  filter: grayscale(100%);
}
.betterpic {
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
      circle,
      transparent 40%,
      rgba(112, 110, 110, 0.411) 75%
    ),
    url("https://images.unsplash.com/photo-1613318286980-4b3dd8475772?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80");
  filter: grayscale(100%);
  background-size: cover;
  background-position: center center;

  background-blend-mode: color-burn;
}

.shelf {
  margin: 0 2rem;
  height: 2rem;
  background-color: brown;
  box-shadow: inset 0 1px 30px rgba(0, 0, 0, 0.685), 5px 15px 30px rgb(0, 0, 0);
  z-index: 10;
}
.project {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__link-container {
    height: 100%;
    position: relative;
  }
  &__pic {
    height: 100%;
    transition: all 0.5s;
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    right: 0;

    z-index: 8;
    color: rgba(0, 0, 0, 0.123);
    background-image: radial-gradient(transparent, rgb(124, 122, 122));
    transition: all 0.5s;
    &:hover {
      color: black;
      & + .project__pic {
        filter: blur(10px);
      }
    }
  }

  &__shelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    perspective: 100rem;
  }
  &__img {
    height: 100vh;
    width: 100vw;
    background-color: purple;
    z-index: -10;

    transform: translateY(-50vh) rotateX(70deg);
  }
}

.board {
  &__tech {
    font-family: "DM Serif Display", serif;

    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    font-size: 2rem;
    gap: 2rem;
    justify-content: space-evenly;
    @media screen and (max-width: 600px) {
      font-size: 1.6rem;
    }
    &__react {
      height: 5rem;
      @media screen and (max-width: 600px) {
        height: 3rem;
      }
    }
  }
}

.proj {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: translateY(15rem);
  height: 20rem;

  &__title {
    font-family: "Cookie", cursive;
    font-size: 5rem;
    transition: all 0.4s;
    @media screen and (max-width: 600px) {
      font-size: 3rem;
    }
    &--load {
      color: transparent;
      opacity: 0;
    }
  }

  &__techs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.7s;
    &--load {
      opacity: 0;
      color: transparent;
    }
  }
}
