@font-face {
  font-family: Sunday;
  src: url("../Sunday.otf");
}
.header {
  overflow: hidden;
  opacity: 0;
  color: #473d3d;
  background-color: #473d3d;
  background-image: url("../img/table3.jpg");
  background-size: contain;
  transition: all 0.4s;
  &__main-title {
    font-family: "DM Serif Display", serif;
    font-size: 5rem;
    margin: 5rem;

    color: rgb(236, 161, 108);
  }

  
  &__title {
    position: absolute;
    opacity: 0;
  }

  &__container {
   
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20rem;
    margin-top: 20rem;
    @media screen and (max-width: 700px) {
     flex-direction: column-reverse;
     gap: 8rem;
    }
    
  }
  &__env {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  &__curri {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  &__about {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  &__mug {
    align-self: flex-start;
    margin: 0 10rem;
    transform: rotate(-30deg);
  }
  &__content {
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
  }

  &__projects {
    height: 20rem;
    transition: all 0.4s;
    transform: rotate(-10deg);
    cursor: pointer;
    &:hover {
      box-shadow: 5px 0 400px black, inset 0 0 70px black;
      transform: scale(1.2);

    }
    
    &__text {
      color: rgb(236, 161, 108);
      font-size: 5rem;
      font-family: "Cookie", cursive;
      cursor: pointer;
    }
    &__img {
      height: 100%;
      border: 10px solid transparent;
      border-image: url(../img/borderphoto.png) 90;
    }
  }
}
