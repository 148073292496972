.footer{
    // height: 5rem;
    // width: 100vw;
    background-color:black;
    // margin-top: 4rem;
    font-family: "Cookie", cursive;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content{
        background-color: black;
        padding: 1rem;
        color: bisque;
        margin-top: 3rem;
        border-radius: 5px;
        // border: 2px solid brown;

    }
    &__text{

    }

 

}