@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Atomic+Age&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bevan&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rye&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  // overflow: hidden;
  @media screen and (min-width: 1500px){
    font-size: 80%;
}
@media screen and (max-width: 1100px){
  font-size: 60%;
}
@media screen and (max-width: 900px){
  font-size: 55%;
}
}
body {
  background-color: black;
  color: white;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  // overflow-y: hidden;
}

.layout {
  // height: 200vh;
  // overflow-x: hidden;
  // background-color: aqua;
  // border: 2px solid blue;
  // overflow: hidden;
  perspective: 150rem;
  perspective-origin: bottom ;
}
