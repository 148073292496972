.menu {
  // // position: fixed;
  // left: 0;

 
  // top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  background-color: rgb(71, 7, 7);
  border-radius: 5px;
  padding: 2px;
  z-index: 20;  
  align-self: center;
  width: fit-content;
  transform: translateY(-85vh);
  @media screen and (max-width: 900px){
    //  left: 50%;
      // transform: translateX(-50%);
      // width: 80vw;
      
  }
  @media screen and (max-width: 600px){
    flex-direction: column;
    // width: 100vw;
  }
  // &__transition {
  //   height: 100vh;
  //   width: 150vw;
  //   position: fixed;
  //   background-color: black;
  //   opacity: 0;
  //   // top: 50rem;
  //   top: 0;
  //   left: -30vw;
  //   right: 0;
  //   bottom: 0;
  // }
  &__container {
    border: 2px solid rgb(82, 18, 18);
    // height: 100%;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
   
    gap: 1rem;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: inset 2px 0 15px rgba(0, 0, 0, 0.555),
      2px 0 15px rgba(0, 0, 0, 0.555);
      
      @media screen and (max-width: 900px){
      flex-direction: row;
      justify-content: space-evenly;
      padding: .5rem;
      flex-wrap: wrap;
      
    }
  }
  &__title {
    font-family: "Love Ya Like A Sister", cursive;

    background-color: bisque;
    color: rgba(0, 0, 0, 0.425);
    border: 3px solid rgb(88, 70, 47);
    padding: 2px 5px;
    font-size: 1.6rem;
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.555);
    transition: all 0.6s;
    @media screen and (max-width: 450px){
      font-size: 1.2rem;
    }
  }
  &__switch {
    height: 50px;
    width: 50px;
    @media screen and (max-width: 450px){
      height: 40px;
    width: 40px;
    }
    border-radius: 50%;
    background-color: rgb(99, 30, 30);
    box-shadow: inset 2px 0 15px rgba(0, 0, 0, 0.555),
      2px 0 5px rgba(0, 0, 0, 0.555);
    border: 2px solid rgb(78, 24, 24);
    display: flex;
    align-items: center;
    justify-content: center;
    &__circle {
      height: 20px;
      width: 20px;
      // background-color: rgb(200, 196, 255);
      border-radius: 50%;
      border: 2px solid rgb(128, 73, 73);
      box-shadow: inset -2px 0 7px rgba(0, 0, 0, 0.555);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__hole {
      height: 16px;
      width: 8px;
      background-color: rgb(49, 49, 49);
      box-shadow: inset 2px 0 17px rgb(0, 0, 0);
      border-radius: 50rem;
    }
    &__tip {
      height: 8px;
      width: 8px;
      background-color: rgb(165, 78, 78);
      border-radius: 50%;
      transform: translateY(8px);
      box-shadow: inset -2px 0 7px rgba(0, 0, 0, 0.555);
      transition: all 0.6s;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    &:hover {
      .menu__title {
        color: rgba(0, 0, 0, 0.87);
      }
      .menu__switch__tip {
        transform: translateY(0);
      }
    }
  }
}
